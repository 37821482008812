export const codeList = [
  {
    id: "a5967b5c-a42a-402f-96db-93b0e3946f58",
    description: "4INNOVATIONTECNOLOGIA",
  },
  { id: "9d4d3ac1-eb82-4e76-abb8-d6dedd72f719", description: "ALIAINCLUI" },
  {
    id: "325f8526-72de-4532-852c-df68463451bf",
    description: "ANTILHAS",
  },
  { id: "ed8c6c2b-1649-49e7-842d-c91e823f96ae", description: "ARMORIIMAK" },
  { id: "7c266e3a-00f2-4abd-87df-d6f6d83389a1", description: "ATHIE" },
  { id: "d900509c-26dd-46eb-9aec-b14805e38bae", description: "BIZERBA" },
  { id: "e7e3b04d-44b9-4278-ad07-a6feab1b0a8f", description: "COMPEX" },
  { id: "c38c15c6-91f4-4f8d-89d1-3de7570d4f66", description: "CUSTOM" },
  {
    id: "dc07d05a-ac49-48d4-a6db-3d1cf1ee4b31",
    description: "ELGIN",
  },
  {
    id: "ab9d502c-2aff-4a6d-a640-f94f7959f386",
    description: "EURECICLO",
  },
  { id: "a56325fb-f2dc-4879-957d-d973ec300381", description: "FRITOP" },
  { id: "ac0c7d9e-430d-4dd7-adbf-98661d8aa6a4", description: "GIC" },
  { id: "6898636f-b7d5-4b89-8963-45effdf17b89", description: "GLOBALTERA" },
  { id: "f29f715e-06c3-4a8f-91ce-1b85e84569eb", description: "GREENRETAIL" },
  {
    id: "fecd68b3-5893-45af-9a84-60234728c42c",
    description: "IMENDES",
  },
  { id: "ca6d177e-f294-4fb5-9f68-4a285535452e", description: "KNC" },
  {
    id: "c0b08082-e7b1-438c-9594-7ffccc8caffd",
    description: "LAURENTI",
  },
  { id: "be3c41ca-ac2e-4ef0-b27e-7454dca0eefa", description: "MOKI" },
  {
    id: "fd398bd1-bee5-4385-87e0-b22df188b2c4",
    description: "NEOGRID",
  },
  { id: "77802430-ed3b-4a4f-9731-9c20d4723177", description: "NETCONVSHOP" },
  { id: "a13c1180-f201-4367-909b-e7691e3285bc", description: "NEXERGROUP" },
  { id: "6f8a145d-f6f8-400d-93c7-80836f18cae8", description: "NEXTOP" },
  { id: "7966acfb-0428-43ac-848f-1efb378924dd", description: "POLPANORTE" },
  { id: "95cdfcc8-2ec3-45ad-a206-81094aac0837", description: "PRIMEINTERWAY" },
  { id: "94723ab5-6c71-4ce7-9ec5-4075a1869416", description: "SCANSOURCE" },
  { id: "3b5a8004-7b5c-4e8b-a2f9-69d3ad43b27a", description: "SQUADFY" },
  { id: "01ebaa4d-c526-4e04-9a16-464f19251cde", description: "SUPERTROCO" },
  {
    id: "3769eb70-8bc1-4f76-ac92-9eb400d2e4e0",
    description: "TIAL",
  },
  {
    id: "56d9f0f9-b3b8-4f0d-b21b-45e5b32c0637",
    description: "TOLEDO",
  },
  { id: "4c3a2e09-b9e1-4fde-9352-0adfa177ad15", description: "TOSHIBA" },
  {
    id: "41ae2f92-3547-4584-bb96-2a2b14ce4b6c",
    description: "TSCTECNOLOGY",
  },
  { id: "a19a0867-a387-4339-99cc-ed8f7d456574", description: "TUVRHEINLAND" },
  { id: "c1bc9b16-54fd-4bcd-8276-d74638d23b98", description: "ZEBRA" },
  { id: "376ae4f7-2bb1-41bc-8242-fa5c701be748", description: "STANDGS1" },
  {
    id: "ce599671-46af-4b76-8fd4-1d12dd17adca",
    description: "STAND2D",
  },
  {
    id: "7f4f6233-ec35-4847-b434-8fd3066fb537",
    description: "LOUNGECAFE",
  },
  { id: "d8ea767e-c714-4c99-8788-90e79c5ba22d", description: "INDEFINIDO1" },
  {
    id: "c16373f2-392d-4e06-9740-c9aefa4c951c",
    description: "INDEFINIDO2",
  },
  { id: "7e73b477-d9b5-401b-96c1-1e337e66fe19", description: "INDEFINIDO3" },
  {
    id: "e3f156fe-410c-4a2b-8468-7e4ee5258480",
    description: "INDEFINIDO4",
  },
  {
    id: "2371a01b-ced9-4c1f-bace-3eb9f3068f7a",
    description: "INDEFINIDO5",
  },
  {
    id: "de4a829d-aa8d-4512-a6c9-313bb233df9d",
    description: "INDEFINIDO6",
  },
  {
    id: "d88a3e47-417b-4582-a94f-901da6a1a46c",
    description: "INDEFINIDO7",
  },
  { id: "04f1cccd-9df4-4ac2-b8a2-a04775847188", description: "INDEFINIDO8" },
  { id: "13dc8b21-c008-4874-b231-de2e2fbde5b5", description: "INDEFINIDO9" },
  {
    id: "799bced7-de18-46fa-b930-c4e61d5be973",
    description: "INDEFINIDO10",
  },
  { id: "a7b29653-dc57-4299-9413-87071ccb7058", description: "INDEFINIDO11" },
  { id: "d96ca7c2-69e8-4a49-b867-82077dc88731", description: "INDEFINIDO12" },
  { id: "77953f71-9860-4489-9e50-a582c324ddf8", description: "AVERYDENNISON" },
  { id: "031113ed-0ed6-4971-ad9b-3f5df2e40b7e", description: "AGUASPRATA" },
];
