import axios from "axios";
import { standsIds } from "../pages/prpetro/standsIds";
const urlBase = "https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-ranking";

async function createEarnedPoints(dataRanking, event, login) {
  const data = JSON.stringify({ ...dataRanking });
  var config = {
    method: "post",
    url: `${urlBase}/events/${event}/logins/${login}/earnedpointcreate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
async function getTotalPoints(event, login) {
  const data = JSON.stringify({ event, login });
  var config = {
    method: "get",
    url: `${urlBase}/events/${event}/logins/${login}/totalpoints`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function verifyUser({ eventId, userId }) {
  var config = {
    method: "get",
    url: `${urlBase}/events/${eventId}/logins/${userId}/earnedpointsandgroups`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { createEarnedPoints, getTotalPoints, verifyUser };