import axios from "axios";
const urlBase = "https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile";

async function loginForm({ email, senha }) {
  const data = JSON.stringify({
    email: email,
    senha: senha,
  });

  var config = {
    method: "post",
    url: `${urlBase}/signin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function QRCodeApp({ data }) {
  const config = {
    method: "post",
    url: `${urlBase}/functions/cncsenac/release`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function QRCodeStaff({ data }) {
  const config = {
    method: "post",
    url: `${urlBase}/functions/cncstaff/release`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error.response);
      return error.response.data;
    });
}

async function getPoints({ idEvent, search, offset, limit }) {
  const config = {
    method: "get",
    url: `${urlBase}/functions/cncstaff/event/${idEvent}/released?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ""}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getFunctions(participanteId, eventId) {
  var config = {
    method: "get",
    url: `${urlBase}/functions/cncsenac/login/${participanteId}/event/${eventId}/released`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { loginForm, QRCodeApp, QRCodeStaff, getPoints, getFunctions };